<template>
    <div class="fondo menu">
        <div class="bkg-yellow">
            <div class="container-fluid p-3" >
                <div class="row">
                    <div class="col-md-12">
                        <!-- Your Chat Plugin code -->
                            <div id="fb-root"></div>

                            <div id="fb-customer-chat" class="fb-customerchat"></div>
                            <div class="logo p-5 d-md-none">
                                <img @click="goBack()" src="/images/logo-vallemio-white.png" class="img-fluid" alt="VALLE MIO" >
                            </div>
                            <div class="logo w-25 m-auto p-5 d-none d-md-block">
                                <img @click="goBack()" src="/images/logo-vallemio-white.png" class="img-fluid" alt="VALLE MIO" >
                            </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pdf bkg-top-yellow-drop">
            <VuePdfEmbed :rotation="360" :source="pdf" />
            <div class="d-block sello-pdf text-center w-25">
                <img class="img-fluid" alt="VALLE MIO" src="/images/sello-negro.png" />
            </div>
        </div>
        <div class=" bkg-pink ">
            <div class="bkg-white-drop" >
                <div class="container-fluid footer-content p-3">
                    <div class="row" style="height:100%">
                        <div class="col-12 col-md-4 p-5 m-auto">
                            <div class="row " style="height:100%">
                                <div class="col-6 text-center">
                                    <a href="https://www.facebook.com/ValleMio.mx" target="_blank" class="text-white social"><facebookIcon /></a>
                                </div>
                                <div class="col-6 text-center">
                                    <a href="https://www.instagram.com/vallemio.mx" target="_blank" class="text-white social"><InstagramIcon /></a>
                                </div>
                                <div class="col-12 pt-5">
                                    <p class="text-white m-auto small pt-2 pb-2 text-center"><b>{{ano}}, VALLE MIO ®</b> <br> <small><a href="https://www.ingavanzada.com" target="_blank">DESARROLLADO POR IASC</a></small> </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 d-none d-md-block"></div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
import facebookIcon from 'vue-material-design-icons/Facebook'
import InstagramIcon from 'vue-material-design-icons/Instagram'

export default {
    name: 'MenuReader',
    components:{facebookIcon,InstagramIcon,VuePdfEmbed},
    data() {
        return {
            pdf: '/PDF/MENU_VALLE_MIO.pdf',
            ano: new Date().getFullYear(),
        }
    },
    mounted(){
        this.facebookApp()
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
        facebookApp(){
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "103768864406727");
            chatbox.setAttribute("attribution", "biz_inbox");
            delete window.FB
            window.fbAsyncInit = function() {
                window.FB.init({
                xfbml            : true,
                version          : 'v15.0'
                });
            };

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
    }
}
</script>
