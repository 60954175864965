<template>
  <section>
    <div class="bkg-space bkg-blue"></div>
    <div class="bkg-top-blue-drop" >
      <!-- Your Chat Plugin code -->
      <div id="fb-root"></div>
      <div id="fb-customer-chat" class="fb-customerchat"></div>
      <div class="container-fluid p-3" >
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-center">
                <router-link to="/"><img class="img-fluid logo"  alt="VALLE MIO" src="/images/sello-negro.png" /></router-link></div>
            </div>
      </div>
    </div>
    <div class="container ">
      <Transition name="slide-fade" key="panel2">
        <div class="row">
          <div class="col-12 text-center p-5">
            <router-link to="/menu" class="btn btn-pink btn-lg rounded-pill">CONOCE NUESTRO MENU</router-link>
          </div>
        </div>
      </Transition>
    </div>
    <div class="container pb-4">
    
      <h2 class="text-center">Creamos lo mejor para ti</h2>
      <carousel :itemsToShow="1" :wrapAround="true" :transition="500">
        <slide v-for="(slide,x) in images" :key="x">
          <img :src="slide.pathLong" class="carousel-image" alt="valle mio" />
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
    <div class="container ">
      <Transition name="slide-fade" key="panel2">
        <div class="row">
          <div class="col-md-8 col-12 offset-md-2 text-center ">
              <video src="/images/VALLE_MIO.MOV" style="width:100%;height:auto" class="border rounded" autoplay></video>
          </div>
          <div class="col-12 text-center ">
              <h2>Valle Mío,<br> Una Familia, Una Historia, Una Tradición</h2>
              <p class="">Con más de 38 años de estar atendiendo en la Ciudad de Valle Hermoso, Tamaulipas, 
                  En La Michoacana de Valle Hermoso estamos comprometidos con los más altos estándares de calidad 
                  y frescura, para seguir ofreciendo ese sabor inigualable que nos distingue, manteniendo la visión 
                  de "Doña Sofía", fundadora de este ya emblemático lugar que forma parte del Corazón de la Ciudad.</p>
              <p>Se ha estado realizando un ajuste al nombre y renovación de imagen, esto se hizo buscando una 
                  distinción a todas las demás Michoacanas y peleterías, que aunque venden productos similares, nunca 
                  serán los mismos, buscando dar a la ciudad que nos vio crecer un distintivo en nuestros productos e 
                  imagen y para ser un pedacito de Valle Hermoso en cualquier lugar que nos encontremos compartiendo 
                  el tradicional sabor que nos distingue.</p>
          </div>        

          <div class="col-12  ">
            <div class="rounded-circle m-auto">
              <img class="img-fluid " alt="Elote" src="/images/elote.jpg" />
            </div>
          </div>
              
        </div>
      </Transition>
    </div>
    <div class="container ">
      <div class="row">
        <div class="col-md-6 col-12">
          <h2 class="text-center">Visita nuestra sucursal</h2>
          <div class="row">
              <div class="col-2">
                <MapMarkerIcon />
              </div>
              <div class="col-10"> 
                <p class="d-md-none text-center">Cosme Santos SN, Zona Centro,<br> 87500 Valle Hermoso, Tamps.</p>
                <p class="d-md-block d-none text-left">Cosme Santos SN, Zona Centro,<br> 87500 Valle Hermoso, Tamps.</p>
              </div>
            </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="map">
            
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.2328230983544!2d-97.81578519174633!3d25.674538689730547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86655844a1a330d7%3A0x5b4413324e71a723!2sValle%20Mio%20Paleteria%20-%20Valle%20Hermoso%20(Tamaulipas)!5e0!3m2!1sen!2smx!4v1672272414626!5m2!1sen!2smx" 
            
            style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>  
        </div>
      </div>
    </div>
    
    <div class=" bkg-pink ">
      <div class="bkg-white-drop" >
        <div class="container-fluid footer-content p-3">
          <div class="row" style="height:100%">
            <div class="col-12 col-md-4 p-5 m-auto">
              <div class="row " style="height:100%">
                <div class="col-6 text-center">
                  <a href="https://www.facebook.com/ValleMio.mx" target="_blank" class="text-white social"><facebookIcon /></a>
                </div>
                <div class="col-6 text-center">
                  <a href="https://www.instagram.com/vallemio.mx" target="_blank" class="text-white social"><InstagramIcon /></a>
                </div>
                <div class="col-12 pt-5">
                  <p class="text-white m-auto small pt-2 pb-2 text-center"><b>{{ano}}, VALLE MIO ®</b> <br> <small><a href="https://www.ingavanzada.com" target="_blank">DESARROLLADO POR IASC</a></small> </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 d-none d-md-block"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </template>
  
  <script>
  import facebookIcon from 'vue-material-design-icons/Facebook'
  import InstagramIcon from 'vue-material-design-icons/Instagram'
  import MapMarkerIcon from 'vue-material-design-icons/MapMarker'
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

  export default {
    name: 'HomeValleMio',
    components:{facebookIcon,InstagramIcon,MapMarkerIcon,Carousel,
    Slide,
    Pagination,
    Navigation,},
    data(){
      return{
        ano: new Date().getFullYear(),
        images: [],
      }
    },
    mounted(){
        this.facebookApp()
        this.importAll(require.context('../assets/productos/', true, /\.(png|jpe?g|gif)$/i));
    },
    methods:{
      importAll(r) {
        r.keys().forEach(key => (this.images.push({ pathLong: r(key), pathShort: key })));
      },
      facebookApp(){
          var chatbox = document.getElementById('fb-customer-chat');
          chatbox.setAttribute("page_id", "103768864406727");
          chatbox.setAttribute("attribution", "biz_inbox");
          delete window.FB
          window.fbAsyncInit = function() {
              window.FB.init({
              xfbml            : true,
              version          : 'v15.0'
              });
          };

          (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
      },
    }
  }
  </script>
  